import * as React from 'react';
import {
    BooleanInput,
    Create,
    DateInput,
    NumberInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    minValue,
} from 'react-admin';
import { trimSpaces } from '../../transformers';

export const FinamInstrumentInfoCreate = () => (
    <Create title="Create finam instrument detail" transform={trimSpaces}>
        <SimpleForm>
            <TextInput
                label="Instrument"
                source="instrument"
                validate={required()}
            />
            <TextInput
                label="Description"
                source="description"
                validate={required()}
            />
            <TextInput
                label="Asset Type"
                source="asset_type"
                validate={required()}
            />
            <NumberInput
                label="Tick Size"
                source="tick_size"
                validate={required()}
            />
            <NumberInput
                label="Tick Price"
                source="tick_price"
                validate={[
                    required(),
                    minValue(1e-10, 'Tick price should be greater than 0.'),
                ]}
            />
            <TextInput
                label="Tick Price Currency"
                source="tick_price_currency"
            />
            <SelectInput
                key="tick_price_currency_broker"
                label="Tick Price Currency Broker"
                source="tick_price_currency_broker"
                choices={[
                    { id: 'cbrf', name: 'CBRF' },
                    { id: 'exante', name: 'Exante' },
                    { id: 'trading_view', name: 'TV' },
                    { id: 'refinitiv', name: 'Refinitiv' },
                    { id: 'refinitiv_seconds', name: 'Refinitiv Seconds' },
                    { id: 'finam', name: 'Finam' },
                    { id: 'binance', name: 'Binance' },
                    { id: 'moex', name: 'MOEX' },
                    { id: 'cme', name: 'CME' },
                ]}
                alwaysOn
            />
            <NumberInput
                label="Initial Margin"
                source="init_margin"
                validate={required()}
            />
            <NumberInput
                label="Commission"
                source="commission"
                validate={required()}
            />
            <NumberInput
                label="Contract Size"
                source="contract_size"
                validate={required()}
            />
            <NumberInput
                label="Max Price"
                source="max_price"
                validate={required()}
            />
            <TextInput
                label="Exchange"
                source="exchange"
                validate={required()}
            />
            <DateInput
                label="Expiry date"
                source="expiry_date"
                validate={required()}
                inputProps={{ min: '1000-01-01', max: '9999-01-01' }}
            />
            <BooleanInput
                label="Is fixed brokerage commission"
                source="is_fixed_brokerage_commission"
                validate={required()}
                defaultValue={true}
            />
            <NumberInput
                label="Brokerage maker commission"
                source="brokerage_maker_commission"
                validate={required()}
                defaultValue={0.45}
            />
            <NumberInput
                label="Brokerage taker commission"
                source="brokerage_taker_commission"
                validate={required()}
                defaultValue={0.45}
            />
            <BooleanInput
                label="Is fixed exchange commission"
                source="is_fixed_exchange_commission"
                validate={required()}
                defaultValue={false}
            />
            <NumberInput
                label="Exchange maker commission"
                source="exchange_maker_commission"
                validate={required()}
                defaultValue={0}
            />
            <NumberInput
                label="Exchange taker commission"
                source="exchange_taker_commission"
                validate={required()}
                defaultValue={0.0198}
            />
        </SimpleForm>
    </Create>
);
