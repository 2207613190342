import React, { FC } from 'react';
import {
    TopToolbar,
    FilterButton,
    TextField,
    TextInput,
    CreateButton,
    Datagrid,
    List,
    BulkDeleteButton,
    usePermissions,
    EditButton,
    FunctionField,
    NumberInput,
} from 'react-admin';

import Score from '@mui/icons-material/Score';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';
import { format } from 'date-fns';

export const PriceDeviationAlertsIcon = Score;

const listFilters = [
    <NumberInput key="id" label="ID" source="id" alwaysOn />,
    <TextInput
        key="instrument_name"
        label="Instrument Name"
        source="instrument_name"
        alwaysOn
    />,
    <TextInput key="user_tag" label="User Tag" source="user_tag" alwaysOn />,
];

const PriceDeviationAlertActions: FC = () => {
    const { permissions } = usePermissions();
    return (
        <>
            <TopToolbar>
                <FilterButton />
                {permissions &&
                    (permissions.includes('developer') ||
                        permissions.includes('manager') ||
                        permissions.includes('head') ||
                        permissions.includes('senior operator')) && (
                        <CreateButton />
                    )}
            </TopToolbar>
        </>
    );
};

const LastAlertField = (record: any) => {
    if (!record) return null;
    if (!record.last_alert_time) return 'NO ALERTS TODAY';
    const lastAlertTime = new Date(record.last_alert_time);
    const formattedExpTime = format(lastAlertTime, 'yyyy-MM-dd HH:mm:ss');
    return <span>{formattedExpTime}</span>;
};

const formatInitPrice = (record: any) => {
    if (!record) return null;
    if (!record.init_price) return '';
    const roundedPrice = Math.round(parseFloat(record.init_price) * 1e5) / 1e5;
    return <span>{roundedPrice}</span>;
};

const formatCurrentPrice = (record: any) => {
    if (!record) return null;
    if (!record.current_price) return '';
    const roundedPrice =
        Math.round(parseFloat(record.current_price) * 1e5) / 1e5;
    return <span>{roundedPrice}</span>;
};

const formatDeviationPercent = (record: any) => {
    if (!record) return null;
    if (!record.deviation_percent) return '';
    return <span>{record.deviation_percent}</span>;
};

const formatAlertingStep = (record: any) => {
    if (!record) return null;
    if (!record.alerting_step) return '';
    return <span>{record.alerting_step}</span>;
};

const formatCurrentDeviation = (record: any) => {
    if (!record) return null;
    if (!record.init_price || !record.current_price) return ''; // Return empty if either price is missing

    // Calculate the deviation percentage
    const deviation =
        ((record.current_price - record.init_price) / record.init_price) * 100;

    // Determine color based on deviation comparison with deviation_step
    const color =
        Math.abs(deviation) > record.deviation_percent ? 'red' : 'green';

    // Return the deviation percentage with conditional color styling
    return <span style={{ color: color }}>{deviation.toFixed(2)}%</span>;
};

export const PriceDeviationAlertList = () => {
    const { permissions } = usePermissions();
    return (
        <List
            filters={listFilters}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            sort={{ field: 'id', order: 'DESC' }}
            empty={false}
            actions={<PriceDeviationAlertActions />}
            hasCreate
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField label="Id" source="id" />
                <TextField label="Instrument Name" source="instrument_name" />
                <FunctionField
                    label="Initial Price"
                    render={record => formatInitPrice(record)}
                />
                <FunctionField
                    label="Current Price"
                    render={record => formatCurrentPrice(record)}
                />
                <FunctionField
                    label="Current Deviation"
                    render={record => formatCurrentDeviation(record)}
                />
                <FunctionField
                    label="Deviation Percent"
                    render={record => formatDeviationPercent(record)}
                />
                <FunctionField
                    label="Alerting Step"
                    render={record => formatAlertingStep(record)}
                />
                <FunctionField
                    label="Last Alert Time"
                    render={record => LastAlertField(record)}
                />
                <TextField label="User Tag" source="user_tag" />
                {permissions &&
                    (permissions.includes('developer') ||
                        permissions.includes('manager') ||
                        permissions.includes('head') ||
                        permissions.includes('senior operator')) && (
                        <EditButton />
                    )}
            </Datagrid>
        </List>
    );
};
