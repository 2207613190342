import {
    Create,
    NumberInput,
    regex,
    required,
    SimpleForm,
    TextInput,
} from 'react-admin';
import React from 'react';

export const validateUserTag = regex(
    /^[a-zA-Z0-9_\s.-@]+$/,
    'Invalid tag format. Only alphanumeric characters, _, ., -, @ and spaces are allowed.'
);
export const validatePositiveFloat = value => {
    if (!/^\d+(\.\d+)?$/.test(value)) {
        return 'Invalid format. Only positive floats are allowed.';
    }
    if (parseFloat(value) === 0) {
        return 'Zero is not allowed. Only positive floats are allowed.';
    }
    return undefined;
};

export const PriceDeviationAlertCreate = () => (
    <Create title="Create price deviation alert" redirect="list">
        <SimpleForm>
            <TextInput
                label="Instrument Group"
                source="instrument_name"
                validate={[required(), validateUserTag]}
                helperText="Например: CL, HG"
            />
            <NumberInput
                label="Deviation Percent"
                source="deviation_percent"
                validate={[required(), validatePositiveFloat]}
                helperText="Отклонение, после которого прислать первый алерт"
            />
            <NumberInput
                label="Alerting step"
                source="alerting_step"
                validate={[required(), validatePositiveFloat]}
                helperText="Шаг алертинга (%) после отклонения на deviation_percent"
            />
            <TextInput
                label="Users to notify"
                source="user_tag"
                validate={[required(), validateUserTag]}
                helperText="Например: @biba @boba"
            />
        </SimpleForm>
    </Create>
);
