import React from 'react';
import {
    Edit,
    SimpleForm,
    required,
    number,
    TextInput,
    NumberInput,
} from 'react-admin';
import {
    validatePositiveFloat,
    validateUserTag,
} from './PriceDeviationAlertCreate';

export const PriceDeviationAlertEdit = () => (
    <Edit
        title="Edit price deviation alert"
        mutationMode="pessimistic"
        redirect="list"
    >
        <SimpleForm>
            <TextInput
                label="Instrument group"
                source="instrument_name"
                validate={required()}
            />
            <NumberInput
                label="Deviation Percent"
                source="deviation_percent"
                validate={[required(), number(), validatePositiveFloat]}
            />
            <NumberInput
                label="Alerting step"
                source="alerting_step"
                validate={[required(), number(), validatePositiveFloat]}
            />
            <TextInput
                label="User tag"
                source="user_tag"
                validate={[required(), validateUserTag]}
            />
        </SimpleForm>
    </Edit>
);
