import React from 'react';
import {
    TextField,
    TextInput,
    Datagrid,
    List,
    useRecordContext,
    DateField,
    NumberField,
} from 'react-admin';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { format } from 'date-fns';

export const ActualSnapshotsIcon = AddAPhotoIcon;
const listFilters = [
    <TextInput
        key="snapshot_name"
        label="Snapshot Label"
        source="snapshot_name"
        alwaysOn
    />,
    <TextInput
        key="broker_type"
        label="Broker"
        source="broker_type"
        alwaysOn
    />,
    <TextInput
        key="sampling_type"
        label="Sampling Type"
        source="sampling_type"
        alwaysOn
    />,
];

const DateTimeField = () => {
    const record = useRecordContext();
    if (!record) return null;
    if (!record.created_at) return null;
    const customTime = new Date(record.created_at);
    const formattedTime = format(customTime, 'yyyy-MM-dd HH:mm:ss');
    return <span>{formattedTime}</span>;
};

export const ActualSnapshotsList = () => {
    return (
        <List filters={listFilters} empty={false}>
            <Datagrid>
                <NumberField label="Id" source="id" />
                <TextField label="Snapshot Label" source="snapshot_name" />
                <TextField label="Broker" source="broker_type" />
                <TextField label="Sampling Type" source="sampling_type" />
                <DateField
                    source={'created_at'}
                    transform={DateTimeField}
                    label="Created at"
                    showTime={true}
                />
            </Datagrid>
        </List>
    );
};
