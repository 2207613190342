import React from 'react';
import {
    TextField,
    TextInput,
    Datagrid,
    DateField,
    List,
    BooleanField,
} from 'react-admin';

import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const listFilters = [
    <TextInput
        key="instrument_code"
        label="Instrument Code"
        source="instrument_code"
        alwaysOn
    />,
    <TextInput key="exante_code" label="Exante Code" source="exante_code" />,
    <TextInput key="cqg_code" label="CQG Code" source="cqg_code" />,
    <TextInput
        key="refinitiv_code"
        label="Refinitiv Code"
        source="refinitiv_code"
    />,
    <TextInput key="cme_code" label="CME Code" source="cme_code" />,
    <TextInput key="finam_code" label="Finam Code" source="finam_code" />,
];

export const AllContractsList = () => {
    return (
        <List
            filters={listFilters}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            sort={{ field: 'instrument_code', order: 'ASC' }}
            empty={false}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField label="Instrument Code" source="instrument_code" />
                <DateField label="Switch Date" source="switch_date" />
                <DateField label="Expiration Date" source="expiry_date" />
                <TextField label="Exante Code" source="exante_code" />
                <TextField label="CQG Code" source="cqg_code" />
                <TextField label="Refinitiv Code" source="refinitiv_code" />
                <TextField label="CME Code" source="cme_code" />
                <TextField label="Finam Code" source="finam_code" />
                <BooleanField
                    label="Has Exante contract info"
                    source="has_info_for_exante_contract"
                    sortable={false}
                />
                <BooleanField
                    label="Has CQG contract info"
                    source="has_info_for_cqg_contract"
                    sortable={false}
                />
                <BooleanField
                    label="Has Finam contract info"
                    source="has_info_for_finam_contract"
                    sortable={false}
                />
                <TextField
                    label="Previous contract"
                    source="prev_contract"
                    sortable={false}
                />
                <TextField
                    label="Next contract"
                    source="next_contract"
                    sortable={false}
                />
                <BooleanField
                    label="Has refinitiv subscription"
                    source="has_refinitiv_subscription"
                    sortable={false}
                />
                <BooleanField
                    label="Has exante subscription"
                    source="has_exante_subscription"
                    sortable={false}
                />
                <BooleanField
                    label="Has binance subscription"
                    source="has_binance_subscription"
                    sortable={false}
                />
                <BooleanField
                    label="Has finam subscription"
                    source="has_finam_subscription"
                    sortable={false}
                />
                <BooleanField
                    label="Has cme subscription"
                    source="has_cme_subscription"
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
