import React from 'react';
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    ReferenceField,
} from 'react-admin';

export const RapidTraderShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="Summary">
                    <TextField source="id" />
                    <TextField source="instrument_group" />
                    <ReferenceField
                        source="rapid_portfolio_launch_id"
                        label="Rapid Portfolio Launch Code"
                        reference="rapid_portfolio_launch"
                        link="show"
                        sortable={false}
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
