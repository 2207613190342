import { HistoryFetcherInstrumentList } from './InstrumentList';
import HistoryFetcherInstrumentIcon from '@mui/icons-material/Visibility';
import HistoryFetcherTaskIcon from '@mui/icons-material/Visibility';
import { HistoryFetcherInstrumentEdit } from './InstrumentEdit';
import { HistoryFetcherTaskList } from './TaskList';
import { HistoryFetcherInstrumentCreate } from './InstrumentCreate';
import {
    ActualSnapshotsIcon,
    ActualSnapshotsList,
} from './ActualSnapshotsList';

export const historyFetcherInstruments = {
    icon: HistoryFetcherInstrumentIcon,
    list: HistoryFetcherInstrumentList,
    create: HistoryFetcherInstrumentCreate,
    edit: HistoryFetcherInstrumentEdit,
};

export const historyFetcherTasks = {
    icon: HistoryFetcherTaskIcon,
    list: HistoryFetcherTaskList,
};

export const actual_snapshots = {
    icon: ActualSnapshotsIcon,
    list: ActualSnapshotsList,
};
