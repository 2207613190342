import { useTheme } from '@mui/material';
import { lighten } from '@mui/material/styles';
import React from 'react';
import {
    TextField,
    TextInput,
    Datagrid,
    DateField,
    List,
    BooleanField,
} from 'react-admin';

import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const listFilters = [
    <TextInput
        key="instrument_code"
        label="Instrument Code"
        source="instrument_code"
        alwaysOn
    />,
    <TextInput key="exante_code" label="Exante Code" source="exante_code" />,
    <TextInput key="cqg_code" label="CQG Code" source="cqg_code" />,
    <TextInput
        key="refinitiv_code"
        label="Refinitiv Code"
        source="refinitiv_code"
    />,
    <TextInput key="cme_code" label="CME Code" source="cme_code" />,
    <TextInput key="finam_code" label="Finam Code" source="finam_code" />,
];

function convertDateToUTC(date) {
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );
}

function addDaysToDate(date, days) {
    date.setDate(date.getDate() + days);
    return date;
}

export const ActualContractsList = () => {
    const theme = useTheme();

    const postRowStyle = record => ({
        backgroundColor:
            record.instrument_code != record.active_code
                ? lighten(theme.palette.error.light, 0.5)
                : addDaysToDate(new Date(), 3) >=
                  convertDateToUTC(new Date(Date.parse(record.expiry_date)))
                ? lighten(theme.palette.warning.light, 0.5)
                : theme.palette.background.default,
    });

    return (
        <List
            filters={listFilters}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            sort={{ field: 'instrument_code', order: 'ASC' }}
            empty={false}
        >
            <Datagrid rowStyle={postRowStyle} bulkActionButtons={false}>
                <TextField label="Instrument Code" source="instrument_code" />
                <DateField label="Switch Date" source="switch_date" />
                <DateField label="Expiration Date" source="expiry_date" />
                <TextField label="Active code" source="active_code" />
                <TextField label="Exante Code" source="exante_code" />
                <TextField label="CQG Code" source="cqg_code" />
                <TextField label="Refinitiv Code" source="refinitiv_code" />
                <TextField label="CME Code" source="cme_code" />
                <TextField label="Finam Code" source="finam_code" />
                <BooleanField
                    label="Has Exante contract info"
                    source="has_info_for_exante_contract"
                    sortable={false}
                />
                <BooleanField
                    label="Has CQG contract info"
                    source="has_info_for_cqg_contract"
                    sortable={false}
                />
                <BooleanField
                    label="Has Finam contract info"
                    source="has_info_for_finam_contract"
                    sortable={false}
                />
                <TextField
                    label="Previous contract"
                    source="prev_contract"
                    sortable={false}
                />
                <TextField
                    label="Next contract"
                    source="next_contract"
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
