import React, { useEffect, useState, FC } from 'react';
import {
    TextField,
    List,
    TextInput,
    TopToolbar,
    FilterButton,
    Datagrid,
    CreateButton,
    EditButton,
    usePermissions,
    useDataProvider,
    FunctionField,
} from 'react-admin';

import AddCardIcon from '@mui/icons-material/AddCard';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const requiredPermissions = ['head', 'developer', 'senior operator'];

const checkPermissions = (permissions: string[] | undefined): boolean => {
    return requiredPermissions.some(permission =>
        permissions?.includes(permission)
    );
};

const listFilters = [
    <TextInput key="code" label="Account" source="code__icontains" alwaysOn />,
];

export const AccountIcon = AddCardIcon;

const AccountListActions: FC = () => {
    const { permissions } = usePermissions();
    return (
        <>
            <TopToolbar>
                <FilterButton />
                {checkPermissions(permissions) && <CreateButton />}
            </TopToolbar>
        </>
    );
};

export const AccountList = () => {
    return (
        <List
            title="Accounts"
            filters={listFilters}
            sort={{ field: 'code', order: 'DESC' }}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<AccountListActions />}
        >
            <AccountDataGrid />
        </List>
    );
};

export const AccountDataGrid = () => {
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const [stock, setStock] = useState<{ [id: number]: string }>({});

    useEffect(() => {
        const fetchStock = async () => {
            let page = 1;
            const perPage = 500;
            const sort = { field: 'id', order: 'ASC' };
            const filter = {};
            let done = false;
            let data: { id: any; name: any }[] = [];
            while (!done) {
                const res = await dataProvider.getList('stock_exchange', {
                    pagination: { page, perPage },
                    sort,
                    filter,
                });
                data = data.concat(res.data);
                if (res === undefined || res.data.length < perPage) {
                    done = true;
                }
                page++;
            }
            const accountsHashTable = data.reduce((acc, stock) => {
                acc[stock.id] = stock.name;
                return acc;
            }, {} as { [id: number]: string });
            setStock(accountsHashTable);
        };
        fetchStock();
    }, [dataProvider]);

    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="code" label="Account" />
            <TextField source="oms_url" label="Oms Url" />
            <FunctionField
                label="Stock Exchange"
                render={record =>
                    stock[record.stock_exchange_id] || record.stock_exchange_id
                }
            />
            {checkPermissions(permissions) && <EditButton />}
        </Datagrid>
    );
};
