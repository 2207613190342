import React, { FC } from 'react';
import {
    ResetTaskButton,
    ExcludeTaskButton,
    MetricsButton,
    ResetAllFailedTasksButton,
} from './Buttons';
import {
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    DateTimeInput,
    Labeled,
    List,
    useRecordContext,
    TopToolbar,
    ExportButton,
    FilterButton,
    BulkDeleteButton,
    useListContext,
} from 'react-admin';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';
import { JsonField } from 'react-admin-json-view';

const TaskListActions: FC = () => {
    const { filterValues } = useListContext();
    return (
        <>
            <TopToolbar>
                <FilterButton />
                <ResetAllFailedTasksButton filterValues={filterValues} />
                <ExportButton maxResults={10000} />
            </TopToolbar>
        </>
    );
};

const listFilters = [
    <ReferenceInput
        key="instrument_id"
        label="Instrument"
        source="instrument_id"
        reference="history_fetcher_instruments"
        alwaysOn
    >
        <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>,
    <SelectInput
        key="instrument_broker"
        label="Instrument Broker"
        source="instrument_broker"
        choices={[
            { id: 'exante', name: 'Exante' },
            { id: 'trading_view', name: 'TV' },
            { id: 'refinitiv', name: 'Refinitiv' },
            { id: 'refinitiv_seconds', name: 'Refinitiv Seconds' },
            { id: 'finam', name: 'Finam' },
            { id: 'binance', name: 'Binance' },
            { id: 'moex', name: 'MOEX' },
            { id: 'cme', name: 'CME' },
            { id: 'cbrf', name: 'CBRF' },
            { id: 'barchart', name: 'Barchart' },
            { id: 'cme_span', name: 'CME SPAN' },
        ]}
        alwaysOn
    />,
    <SelectInput
        key="status"
        label="Status"
        source="status"
        choices={[
            { id: 'pending', name: 'Pending' },
            { id: 'in_progress', name: 'In progress' },
            { id: 'failed', name: 'Failed' },
            { id: 'done', name: 'Done' },
            { id: 'excluded', name: 'Excluded' },
        ]}
        alwaysOn
    />,
    <DateTimeInput
        key="date_from"
        label="Date from"
        source="date_from"
        alwaysOn
    />,
    <DateTimeInput key="date_to" label="Date to" source="date_to" alwaysOn />,
];

export const HistoryFetcherTaskShow = () => {
    const record = useRecordContext();
    return (
        <>
            <div>
                <Labeled label="Metrics">
                    <JsonField record={record} source="metrics" />
                </Labeled>
            </div>
            <div>
                <Labeled label="Fetcher information">
                    <JsonField record={record} source="fetcher_info" />
                </Labeled>
            </div>
        </>
    );
};

export const HistoryFetcherTaskList = () => (
    <List
        filters={listFilters}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<CustomPagination />}
        perPage={CustomPerPage}
        actions={<TaskListActions />}
    >
        <Datagrid
            rowClick="expand"
            expand={<HistoryFetcherTaskShow />}
            bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
        >
            <TextField source="id" />
            <ReferenceField
                label="Instrument Name"
                source="instrument_id"
                reference="history_fetcher_instruments"
                sortable={false}
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Broker"
                source="instrument_id"
                reference="history_fetcher_instruments"
                sortable={false}
            >
                <TextField source="broker" />
            </ReferenceField>
            <DateField label="From Time" source="load_from" showTime />
            <DateField label="To Time" source="load_to" showTime />
            <TextField source="status" />
            <DateField label="Start at" source="start_at" showTime />
            <DateField label="Done at" source="done_at" showTime />
            <MetricsButton />
            <ResetTaskButton />
            <ExcludeTaskButton />
        </Datagrid>
    </List>
);
