import {
    PriceDeviationAlertList,
    PriceDeviationAlertsIcon,
} from './PriceDeviationAlertList';
import { PriceDeviationAlertCreate } from './PriceDeviationAlertCreate';
import { PriceDeviationAlertEdit } from './PriceDeviationAlertEdit';

export const priceDeviationAlerts = {
    icon: PriceDeviationAlertsIcon,
    list: PriceDeviationAlertList,
    create: PriceDeviationAlertCreate,
    edit: PriceDeviationAlertEdit,
};
