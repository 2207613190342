import React, { useEffect, useState } from 'react';
import {
    required,
    SimpleForm,
    TextInput,
    Create,
    SelectInput,
    useDataProvider,
} from 'react-admin';

export const AccountCreate = () => {
    const dataProvider = useDataProvider();
    const [stock, setStock] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        const fetchStock = async () => {
            let page = 1;
            const perPage = 500;
            const sort = { field: 'id', order: 'ASC' };
            const filter = {};
            let done = false;
            let data: { id: any; name: any }[] = [];
            while (!done) {
                const res = await dataProvider.getList('stock_exchange', {
                    pagination: { page, perPage },
                    sort,
                    filter,
                });
                data = data.concat(res.data);
                if (res === undefined || res.data.length < perPage) {
                    done = true;
                }
                page++;
            }
            setStock(
                data.map(stock => ({
                    id: stock.id,
                    name: stock.name,
                }))
            );
        };
        fetchStock();
    }, [dataProvider]);
    return (
        <Create title="Create a Account" redirect="list">
            <SimpleForm>
                <TextInput source="code" validate={[required()]} />
                <TextInput source="oms_url" validate={required()} />
                <SelectInput
                    source="stock_exchange_id"
                    choices={stock}
                    validate={required()}
                />
            </SimpleForm>
        </Create>
    );
};
